import { setColorTheme } from '@facephi/ui-react';

export const TableEmpty = () => {
  return (
    <svg
      width="320"
      height="125"
      viewBox="0 0 320 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="error-data"
      data-test="image"
    >
      <ellipse
        opacity="0.1"
        cx="152.5"
        cy="98.5"
        rx="96.5"
        ry="18.5"
        fill={setColorTheme('primary400')}
      />
      <path
        d="M91.6025 24.1515L145.51 15.3145V66.7288L94.2909 64.0276L91.6025 24.1515Z"
        fill={setColorTheme('primary400')}
      />
      <path
        opacity="0.05"
        d="M91.6025 24.1515L145.51 15.3145V66.7288L94.2909 64.0276L91.6025 24.1515Z"
        fill="black"
      />
      <path
        d="M145.511 15.3145L210.309 20.1879L206.723 64.9304L145.511 66.7288V15.3145Z"
        fill={setColorTheme('primary400')}
      />
      <path
        opacity="0.1"
        d="M145.511 15.3145L210.309 20.1879L206.723 64.9304L145.511 66.7288V15.3145Z"
        fill="black"
      />
      <path
        d="M165.369 0L145.511 15.4419L210.1 20.2376L220.655 4.43478L165.369 0Z"
        fill={setColorTheme('primary400')}
      />
      <path
        d="M72 7.01577L120.586 1.09375L145.51 15.2743L91.5411 24.0661L72 7.01577Z"
        fill={setColorTheme('primary400')}
      />
      <path
        d="M157.49 28.5547V98.4531L132.175 95.8583L121.163 94.7363L117.809 94.3856L106.804 93.2565L91.6025 91.6996V24.0664L145.636 27.7412L157.49 28.5547Z"
        fill={setColorTheme('primary400')}
      />
      <path
        d="M210.308 20.2383V88.7825L190.072 92.6963L180.231 94.6003L157.489 99.0007V28.7146L210.308 20.2383Z"
        fill={setColorTheme('primary400')}
      />
      <path
        opacity="0.05"
        d="M210.308 20.2383V88.7825L190.072 92.6963L180.231 94.6003L157.489 99.0007V28.7146L210.308 20.2383Z"
        fill="black"
      />
      <path
        d="M72 38.7584L91.4503 24.0664L156.945 28.5482L147.304 44.8509L72 38.7584Z"
        fill={setColorTheme('primary400')}
      />
      <path
        opacity="0.1"
        d="M72 38.7584L91.4503 24.0664L156.945 28.5482L147.304 44.8509L72 38.7584Z"
        fill="white"
      />
      <path
        d="M210.123 20.2383L157.489 28.7414L174.62 44.3046L231 34.712L210.123 20.2383Z"
        fill={setColorTheme('primary400')}
      />
      <path
        opacity="0.1"
        d="M210.123 20.2383L157.489 28.7414L174.62 44.3046L231 34.712L210.123 20.2383Z"
        fill="white"
      />
      <ellipse
        opacity="0.1"
        cx="247.5"
        cy="83.5"
        rx="14.5"
        ry="2.5"
        fill={setColorTheme('primary400')}
      />
      <ellipse
        opacity="0.1"
        cx="194.5"
        cy="122.5"
        rx="14.5"
        ry="2.5"
        fill={setColorTheme('primary400')}
      />
      <ellipse
        opacity="0.1"
        cx="305.5"
        cy="113.5"
        rx="14.5"
        ry="2.5"
        fill={setColorTheme('primary400')}
      />
      <ellipse
        cx="22.5"
        cy="87"
        rx="22.5"
        ry="4"
        fill={setColorTheme('primary400')}
      />
      <ellipse
        cx="275.5"
        cy="95"
        rx="22.5"
        ry="4"
        fill={setColorTheme('primary400')}
      />
    </svg>
  );
};
