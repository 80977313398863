import { setColorTheme } from '@facephi/ui-react';

export const TableError = () => {
  return (
    <svg
      width="381"
      height="329"
      viewBox="0 0 381 329"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="error-data"
      data-test="image"
    >
      <g fillRule="nonzero" fill="none">
        <ellipse fill="#181922" cx="196.5" cy="164.5" rx="184.5" ry="164.5" />
        <ellipse
          fill={setColorTheme('primary400')}
          cx="68"
          cy="254.5"
          rx="68"
          ry="10.5"
        />
        <ellipse
          fill={setColorTheme('primary400')}
          cx="87.5"
          cy="303.5"
          rx="26.5"
          ry="5.5"
        />
        <ellipse
          fill={setColorTheme('primary400')}
          opacity=".755"
          cx="268.5"
          cy="282.5"
          rx="26.5"
          ry="5.5"
        />
        <ellipse
          fill={setColorTheme('primary400')}
          opacity=".1"
          cx="304"
          cy="235"
          rx="43"
          ry="7"
        />
        <path
          d="M272.575 234h63.866a3.573 3.573 0 0 0 3.057-1.765 3.633 3.633 0 0 0 .075-3.551l-31.918-59.788a3.57 3.57 0 0 0-3.147-1.896 3.57 3.57 0 0 0-3.148 1.896l-31.933 59.788a3.633 3.633 0 0 0 .079 3.557 3.572 3.572 0 0 0 3.069 1.759zm35.996-10.925h-8.127v-8.19h8.127v8.19zm0-13.652h-8.127v-16.384h8.127v16.384z"
          fill="#FFF"
        />
        <path
          d="M272.575 237h63.866a3.573 3.573 0 0 0 3.057-1.765 3.633 3.633 0 0 0 .075-3.551l-31.918-59.788a3.57 3.57 0 0 0-3.147-1.896 3.57 3.57 0 0 0-3.148 1.896l-31.933 59.788a3.633 3.633 0 0 0 .079 3.557 3.572 3.572 0 0 0 3.069 1.759zm35.996-10.925h-8.127v-8.19h8.127v8.19zm0-13.652h-8.127v-16.384h8.127v16.384z"
          fill="#CCC"
        />
        <path
          d="M111 148.528a1.189 1.189 0 0 0-.86 0l-2.53.795c-.886.251-1.75.585-2.582.997-.363.19-.708.418-1.028.68.028-.117.065-.232.112-.342.617-1.454 2.444-1.653 3.715-2.5.703-.465 2.298-1.963 2.889-.596.129.31.224.634.284.966zM98.999 116.993c.047 0 .03 0 .007.008s-.061.008-.007-.008zM71 143.83c-.434.26-1.078.174-1.614.06-2.118-.44-4.455-.756-6.386.089-.003-.319.08-.633.244-.913.278-.433.872-.522 1.36-.642a15.26 15.26 0 0 1 5.014-.356.682.682 0 0 1 .678.43c.255.423.476.877.704 1.332zM88 82l-2-2.556c.127-.102.266-.197.415-.284a2.21 2.21 0 0 1 .965-.156L88 82zM93 78.492 91.089 81 90 78.249a3.838 3.838 0 0 1 1.473-.247 5.308 5.308 0 0 1 1.527.49z"
          fill="#000"
          opacity=".1"
        />
        <path
          d="M75 293.862s8.87-.277 11.542-2.208c2.672-1.931 13.64-4.236 14.305-1.14.665 3.095 13.328 15.399 3.313 15.483-10.014.084-23.262-1.582-25.93-3.232-2.668-1.65-3.23-8.903-3.23-8.903z"
          fill="#A8A8A8"
        />
        <path
          d="M104.344 304.925c-10.015.084-23.268-1.564-25.936-3.176-2.03-1.242-2.842-5.697-3.111-7.749H75s.562 7.174 3.23 8.801c2.668 1.628 15.921 3.276 25.932 3.196 2.894-.024 3.891-1.056 3.836-2.584-.4.92-1.504 1.496-3.654 1.512z"
          fill="#000"
          opacity=".2"
        />
        <path
          d="M292 271.39s-10.445-2.116-13.227-4.938c-2.783-2.822-15.317-7.753-16.71-4.23-1.394 3.524-18.796 15.514-6.962 17.638 11.834 2.125 27.847 2.806 31.33 1.411 3.483-1.395 5.569-9.88 5.569-9.88z"
          fill="#A8A8A8"
        />
        <path
          d="M255.098 278.768c11.84 1.978 27.85 2.627 31.333 1.317 2.648-1.006 4.492-5.837 5.22-8.085l.349.071s-2.09 7.92-5.57 9.242c-3.478 1.32-19.493.66-31.332-1.322-3.416-.57-4.393-1.914-4.025-3.61.293 1.118 1.484 1.97 4.025 2.387z"
          fill="#000"
          opacity=".2"
        />
        <path
          d="M191.188 45.335h-2.47c.956 0 1.73-.747 1.73-1.668 0-.92-.774-1.667-1.73-1.667h-20.001c-.956 0-1.73.746-1.73 1.667s.774 1.668 1.73 1.668h2.468c-.922.042-1.647.775-1.647 1.665s.725 1.623 1.647 1.665h-3.455c-.956 0-1.73.747-1.73 1.668 0 .92.774 1.667 1.73 1.667h20.002c.956 0 1.73-.746 1.73-1.667s-.774-1.668-1.73-1.668h3.456a1.747 1.747 0 0 0 1.566-.809 1.616 1.616 0 0 0 0-1.712 1.747 1.747 0 0 0-1.566-.81zM269.19 106.333h-2.468c.922-.042 1.646-.775 1.646-1.665s-.724-1.624-1.646-1.666h-20.007a1.745 1.745 0 0 0-1.566.809 1.617 1.617 0 0 0 0 1.713c.327.528.93.839 1.566.81h2.467c-.922.042-1.647.775-1.647 1.665s.725 1.623 1.647 1.666h-3.453c-.955 0-1.729.746-1.729 1.667s.774 1.668 1.729 1.668h19.983c.955 0 1.729-.747 1.729-1.668 0-.92-.774-1.667-1.729-1.667h3.477a1.745 1.745 0 0 0 1.565-.81 1.617 1.617 0 0 0 0-1.713 1.745 1.745 0 0 0-1.565-.809zM153.196 94.333h-2.47a1.747 1.747 0 0 0 1.567-.809 1.616 1.616 0 0 0 0-1.713 1.747 1.747 0 0 0-1.566-.81H130.72a1.747 1.747 0 0 0-1.566.81 1.616 1.616 0 0 0 0 1.713c.327.528.93.839 1.566.81h2.47a1.747 1.747 0 0 0-1.567.808 1.616 1.616 0 0 0 0 1.714c.327.527.93.838 1.566.809h-3.46c-.955 0-1.729.746-1.729 1.667s.774 1.668 1.73 1.668h20.002c.956 0 1.73-.747 1.73-1.668 0-.92-.774-1.667-1.73-1.667h3.456a1.747 1.747 0 0 0 1.566-.81 1.616 1.616 0 0 0 0-1.713 1.747 1.747 0 0 0-1.566-.809h.008z"
          fill={setColorTheme('primary400')}
          opacity=".755"
        />
        <g transform="translate(42.727 76)">
          <path
            d="M45.047 3.361c0-1.684-1.213-3.108-2.836-3.331-1.623-.223-3.157.823-3.588 2.448-.43 1.624.374 3.326 1.883 3.981v17.447h2.543V6.459c1.211-.526 1.998-1.746 1.998-3.098zM65.091 89.102h-.001a7.71 7.71 0 0 1-5.54-2.354 7.667 7.667 0 0 1-2.146-5.612c.154-4.066 3.685-7.375 7.87-7.375h21.13l17.389-7.676a1.928 1.928 0 0 1 2.536.977L111 77.583c.43.969-.01 2.1-.98 2.53l-17.125 7.56a13.646 13.646 0 0 1-5.385 1.169l-22.418.26zM13.92 129.67c-.101 0-.202-.01-.301-.026l-11.444-1.92a1.744 1.744 0 0 1-1.14-.715 1.69 1.69 0 0 1-.276-1.303l8.958-44.92c.702-4.04 4.602-6.755 8.71-6.065 4.108.691 6.87 4.526 6.167 8.565l-8.973 45a1.722 1.722 0 0 1-1.701 1.383z"
            fill="#CCC"
          />
          <ellipse
            fill="#E6E6E6"
            cx="6.902"
            cy="132.929"
            rx="6.902"
            ry="6.882"
          />
          <ellipse
            fill="#E6E6E6"
            cx="40.324"
            cy="48.173"
            rx="25.793"
            ry="25.717"
          />
          <ellipse
            fill="#E6E6E6"
            cx="40.324"
            cy="48.173"
            rx="25.793"
            ry="25.717"
          />
          <ellipse
            fill={setColorTheme('primary400')}
            cx="41.051"
            cy="47.449"
            rx="19.254"
            ry="19.197"
          />
          <path
            d="M28.136 47.5c.001-9.48 7.183-17.54 16.91-18.98-7.771-1.264-15.587 2.002-19.938 8.333-4.351 6.331-4.419 14.537-.174 20.934 4.246 6.397 12.006 9.784 19.799 8.638-9.583-1.567-16.595-9.563-16.597-18.925z"
            fill="#000"
            opacity=".2"
          />
          <path
            d="M59.812 129.67H20.836c-3.48-.005-6.3-2.859-6.305-6.38V78.82c.004-3.522 2.825-6.376 6.305-6.38h5.923v1.74c.003 2.455 1.969 4.444 4.394 4.447h18.342c2.426-.003 4.392-1.992 4.394-4.447v-1.74h5.923c3.48.004 6.301 2.858 6.305 6.38v44.468c-.004 3.522-2.824 6.376-6.305 6.38z"
            fill="#E6E6E6"
          />
          <path
            d="M66.117 172.41H50.678v-17.842l-20.162-30.946 12.928-8.44 20.075 30.81a13.989 13.989 0 0 1 2.269 7.404l.33 19.013z"
            fill="#E6E6E6"
          />
          <path fill="#E6E6E6" d="M37.781 184H23.25v-44.189h14.531z" />
          <path
            d="M57.007 143.433H23.64c-1.82-.002-3.295-1.407-3.297-3.142v-11.827c.002-1.734 1.477-3.14 3.297-3.141h33.366c1.82.002 3.296 1.407 3.298 3.141v11.827c-.002 1.735-1.478 3.14-3.298 3.142z"
            fill="#E6E6E6"
          />
          <path
            d="M68.842 178.93H50.859v-11.591h17.983c3.31 0 5.994 2.594 5.994 5.795 0 3.2-2.684 5.795-5.994 5.795zM41.959 184H23.977v-11.59h17.982c3.31 0 5.994 2.594 5.994 5.795 0 3.2-2.684 5.795-5.994 5.795z"
            fill="#CCC"
          />
          <path
            d="M58.975 92.724H44.923c-.734 0-1.33-.648-1.33-1.448 0-.8.596-1.45 1.33-1.45h14.052c.735 0 1.33.65 1.33 1.45 0 .8-.595 1.448-1.33 1.448zM58.975 97.795H44.923c-.734 0-1.33-.648-1.33-1.449 0-.8.596-1.448 1.33-1.448h14.052c.735 0 1.33.648 1.33 1.448 0 .8-.595 1.45-1.33 1.45z"
            fill="#FFF"
          />
          <ellipse
            fill="#E6E6E6"
            cx="112.254"
            cy="70.63"
            rx="6.902"
            ry="6.882"
          />
          <path
            d="M113.707 97.795c-1.404-.001-2.541-1.025-2.543-2.287V13.154c0-1.264 1.139-2.288 2.543-2.288 1.404 0 2.543 1.024 2.543 2.288v82.354c-.002 1.262-1.14 2.286-2.543 2.287z"
            fill="#3F3D56"
          />
          <path
            d="M184.682 11.364c-2.037-1.775-9.034-7.3-18.06-8.309-6.754-.75-14.644 1.024-22.441 8.794-6.255 6.23-12.154 9.183-17.535 8.783-5.494-.412-9.147-4.3-10.836-6.584a.947.947 0 0 0-1.07-.339.951.951 0 0 0-.67.916v28.747c.001.372.216.71.553.87 2.107.994 9.695 4.293 17.334 4.293 4.48 0 8.984-1.135 12.401-4.543a2.31 2.31 0 0 1 .1-.096c6.055-5.868 19.4-4.663 29.137-2.746 4.028.794 7.438 1.706 9.46 2.291.524.151 1.09.048 1.526-.28.436-.326.692-.839.692-1.383V12.67c0-.5-.215-.975-.59-1.305z"
            fill="#F86D70"
          />
          <path
            d="M62.842 36.398c-2.505 1.081-2.401-2.446-3.71-3.656a4.46 4.46 0 0 0-2.91-.858 230.107 230.107 0 0 0-26.202-.223c-3.107.206-6.516.669-8.608 2.97-.533.583-1.093 1.338-1.893 1.312a65.975 65.975 0 0 1-.586-5.26 5.836 5.836 0 0 1 .104-1.975c.215-.738.705-1.364.955-2.085a8.335 8.335 0 0 0 .258-2.815A20.33 20.33 0 0 1 30.287 6.506a9.117 9.117 0 0 0 2.092-1.408c.218-.259.457-.5.714-.72a2.735 2.735 0 0 1 1.662-.396h1.222a11.139 11.139 0 0 0 3.228-.858 7.06 7.06 0 0 1 3.004-.566c1.094.199 2.147.579 3.116 1.124.466.224.953.4 1.455.524.783.18 1.592.171 2.376.326 1.268.322 2.473.856 3.563 1.579A17 17 0 0 1 56.3 8.686a15.792 15.792 0 0 1 2.772 4.497 32.148 32.148 0 0 1 2.582 7.14c.698 3.553.353 7.398 2.006 10.625 1.007 2.026 1.928 4.274-.818 5.45z"
            fill="#FED253"
          />
        </g>
      </g>
    </svg>
  );
};
